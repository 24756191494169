// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Head from 'react-helmet';
import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';

// Components
import Button from 'app/shared/core/Button';
import Container from 'app/shared/core/Container';
import PromptLogin from 'app/shared/modules/auth/PromptLogin';
import Row from 'app/shared/core/Row';
import Section from 'app/shared/modules/Section';

// Misc / Utils
import './style.scss';
import { pageView } from 'app/client/universal-analytics-v2';
import { TrackViewLoginPage } from 'app/shared/models/Clickstream/PageViewEvents';
import pathsManifest from 'app/shared/utils/pathsManifest';
import IconActivity from 'images/icons/activity-teal.svg';
import IconAlert from 'images/icons/alert-teal.svg';
import IconApplications from 'images/icons/ic-applications-teal.svg';
import IconHeart from 'images/icons/heart-teal.svg';
import IconPayments from 'images/icons/payments-teal.svg';
import IconSettings from 'images/icons/settings-teal.svg';
import Text from 'app/shared/core/Text';
import queryUtils from 'app/shared/utils/queryUtils';
import UserItemsTitleBar from 'app/shared/modules/user-items/UserItemsTitleBar';

const StyledDarkText = styled(Text)`
  color: ${colors['$hpx-grey-600']};
`;
const StyledBoldText = styled(Text)`
  font-weight: 700;
`;
class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    const { dispatch, isMobile, isCcpaRedirectUrl } = this.props;

    /**
     * If the user came from Zillow's CCPA portal, take them straight to entering their
     * email - no need to upsell them on why they should create an account.
     */
    this.state = {
      showMobileLoginPrompt: isMobile && isCcpaRedirectUrl,
    };

    dispatch(pageView({ newLaneEvent: TrackViewLoginPage() }));
  }

  handleShowLoginPrompt = () => {
    this.setState({ showMobileLoginPrompt: true });
  };

  getTitle = () => {
    const { isCcpaRedirectUrl } = this.props;
    let title = 'Sign in / Register';

    if (isCcpaRedirectUrl) {
      title = 'Sign in to use our privacy tools';
    }
    return title;
  };

  render() {
    const title = this.getTitle();

    return (
      <div className="LoginPage inline-container">
        <Head
          title="Log in | HotPads"
          meta={[
            {
              name: 'robots',
              content: 'noindex',
            },
          ]}
        />
        {this.state.showMobileLoginPrompt ? (
          <PromptLogin title={title} />
        ) : (
          <Container>
            <UserItemsTitleBar>{title}</UserItemsTitleBar>
            <div className="LoginPage-left">
              <Section className="LoginPage-intro">
                With your personal HotPads account, you can track your favorite properties and receive custom alerts
                about new listings that come to market.
                <div className="LoginPage-mweb-button">
                  <Button full onClick={this.handleShowLoginPrompt}>
                    {title}
                  </Button>
                </div>
              </Section>
              <Row>
                <Text htmlTag="h2" size="md">
                  <strong>Sign in to manage your:</strong>
                </Text>
              </Row>
              <div className="UserItems-Row">
                <div className="UserItems-left-col">
                  <div className="UserItems-icon">
                    <img src={IconHeart} className="UserItems-icon-img" alt="" />
                  </div>
                  <div className="UserItems-info">
                    <h3>Favorites</h3>
                    <Row>
                      <StyledDarkText>Quickly access the properties you like</StyledDarkText>
                    </Row>
                  </div>
                </div>
                <div className="UserItems-right-col">
                  <div className="UserItems-icon">
                    <img src={IconAlert} className="UserItems-icon-img" alt="" />
                  </div>
                  <div className="UserItems-info">
                    <h3>Alerts</h3>
                    <Row>
                      <StyledDarkText>Revisit searches and stay up to date on an area</StyledDarkText>
                    </Row>
                  </div>
                </div>
              </div>
              <div className="UserItems-Row">
                <div className="UserItems-left-col">
                  <div className="UserItems-icon">
                    <img src={IconSettings} className="UserItems-icon-img" alt="" />
                  </div>
                  <div className="UserItems-info">
                    <h3>Settings</h3>
                    <Row>
                      <StyledDarkText>Edit your profile or change your notification preferences</StyledDarkText>
                    </Row>
                  </div>
                </div>
                <div className="UserItems-right-col">
                  <div className="UserItems-icon">
                    <img src={IconActivity} className="UserItems-icon-img" alt="" />
                  </div>
                  <div className="UserItems-info">
                    <h3>Search activity</h3>
                    <Row>
                      <StyledDarkText>See your viewed, contacted, and hidden listings</StyledDarkText>
                    </Row>
                  </div>
                </div>
              </div>
              <Row>
                <StyledBoldText>Take advantage of our extra services:</StyledBoldText>
              </Row>
              <Row>
                <div className="UserItems-Row">
                  <div className="UserItems-left-col">
                    <div className="UserItems-icon">
                      <img src={IconApplications} className="UserItems-icon-img" alt="" />
                    </div>
                    <div className="UserItems-info">
                      <h3>Send applications</h3>
                      <Row>
                        <StyledDarkText>Submit your paperwork to the landlord</StyledDarkText>
                      </Row>
                    </div>
                  </div>
                  <div className="UserItems-right-col">
                    <div className="UserItems-icon">
                      <img src={IconPayments} className="UserItems-icon-img" alt="" />
                    </div>
                    <div className="UserItems-info">
                      <h3>Pay rent</h3>
                      <Row>
                        <StyledDarkText>Send money to your landlord</StyledDarkText>
                      </Row>
                    </div>
                  </div>
                </div>
              </Row>
            </div>
            <div className="LoginPage-right">
              <Section>
                <div className="LoginPage-inline-modal">
                  <PromptLogin showDescription={false} showTitle={false} />
                </div>
              </Section>
            </div>
          </Container>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const query = queryUtils.parse(ownProps.location.search);
  const isCcpaRedirectUrl =
    query.redirectUrl &&
    query.redirectUrl.includes(state.app.clusterSettingDict['node.ccpaRedirectUrl'] || pathsManifest.ccpaPortal);

  return {
    isCcpaRedirectUrl,
    isMobile: state.app.device.screenWidth === 'sm',
  };
};

export default withRouter(connect(mapStateToProps)(LoginPage));
