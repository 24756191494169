import type { ReactNode } from 'react';
import React from 'react';
import './style.scss';

// Define the props interface
interface UserItemsTitleBarProps {
  children: ReactNode;
}

const UserItemsTitleBar: React.FC<UserItemsTitleBarProps> = ({ children }) => {
  return <h1 className="UserItemsTitleBar">{children}</h1>;
};

export default UserItemsTitleBar;
